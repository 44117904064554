import React, { useState, useEffect, useCallback, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { NumericFormat } from "react-number-format";
import axios from "../axiosConfig";
import { toast } from "react-toastify";
import { ConfigProvider, DatePicker, Form, Radio } from "antd";
import Alert from "antd/es/alert/Alert";
import vi_VN from "antd/locale/vi_VN";
import dayjs from "dayjs";
dayjs.locale("vi_VN");

function convertToDateFormat(dateTimeStr) {
  if (!dateTimeStr) return "";

  const [datePart] = dateTimeStr?.split(" ");
  const [year, month, day] = datePart?.split("-");

  return `${day}/${month}/${year}`;
}

function convertToDateSubmit(dateTimeStr) {
  console.log("dateTimeStr", dateTimeStr);

  const [day, month, year] = dateTimeStr.split("/");

  return `${year}-${month}-${day}`;
}

const SupplementCandidateInfo = () => {
  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [wards, setWards] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedWard, setSelectedWard] = useState("");
  const [detailedAddress, setDetailedAddress] = useState("");
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [provinces2, setProvinces2] = useState([]);
  const [districts2, setDistricts2] = useState([]);
  const [wards2, setWards2] = useState([]);
  const [selectedProvince2, setSelectedProvince2] = useState("");
  const [selectedDistrict2, setSelectedDistrict2] = useState("");
  const [selectedWard2, setSelectedWard2] = useState("");
  const [detailedAddress2, setDetailedAddress2] = useState("");
  const formRef = useRef();

  const [formData, setFormData] = useState({
    info_questions: [
      { title: "Điểm mạnh của bản thân ?", answer: "", note: "" },
      { title: "Điểm yếu của bản thân ?", answer: "", note: "" },
      { title: "Làm ngoài giờ, đi công tác xa ?", answer: "", note: "" },
      { title: "Đảm nhận công việc ở tỉnh/ TP khác ?", answer: "", note: "" },
      {
        title:
          "Anh/Chị có bạn bè hay người thân đang làm việc tại Công ty Thiên Lý hay Công ty cùng ngành không? (F&B) ?",
        answer: "",
        note: "",
      },
      {
        title:
          "Anh/Chị đã từng nộp đơn dự tuyển vào làm việc tại Công ty hay chưa ?",
        answer: "",
        note: "",
      },
      {
        title: "Anh/Chị biết thông tin tuyển dụng qua kênh nào ?",
        answer: "",
        note: "",
      },
      { title: "Người tham khảo tại công ty gần nhất ?", answer: "", note: "" },
    ],
  });
  const navigate = useNavigate();
  const { id } = useParams();

  const formatDateVN = (dateTime) => {
    if (!dateTime) return ""; // Kiểm tra nếu không có giá trị, trả về chuỗi rỗng
    return dateTime.split(" ")[0]; // Tách chuỗi tại khoảng trắng và lấy phần đầu tiên (YYYY-MM-DD)
  };

  const handleInputQuestionsChange = (index, value) => {
    const newFormData = { ...formData };
    newFormData.info_questions[index].answer = value;
    setFormData(newFormData);
  };

  // Handle change for radio input
  const handleRadioQuestionsChange = (index, value) => {
    const newFormData = { ...formData };
    newFormData.info_questions[index].answer = parseInt(value, 10);
    setFormData(newFormData);
  };

  const handleChangeNote = (index, value) => {
    const newFormData = { ...formData };
    newFormData.info_questions[index].note = value;
    setFormData(newFormData);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Handle radio button changes
  const handleRadioChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // handle form field address changes
  const handleAddressChange = (e) => {
    setDetailedAddress(e.target.value);
  };

  // Fetch Tỉnh/Thành
  useEffect(() => {
    setLoading(true);
    axios
      .get("https://main.doapp.vn/api/place/vn/province")
      .then((response) => {
        const provinces = response?.data?.data || [];
        provinces.sort((a, b) => {
          if (a.id === 1) return -1; // Hà Nội first
          if (b.id === 1) return 1;
          if (a.id === 79) return -1; // Hồ Chí Minh second
          if (b.id === 79) return 1;
          return 0;
        });
        setProvinces(provinces);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching provinces:", error);

        setLoading(false);
      });
  }, []);
  // Fetch Quận/Huyện khi tỉnh/thành thay đổi
  useEffect(() => {
    if (selectedProvince) {
      setLoading(true);
      axios
        .get(`https://main.doapp.vn/api/place/vn/district/${selectedProvince}`)
        .then((response) => {
          setDistricts(response?.data?.data || []);
          setSelectedDistrict(""); // Reset selected district
          setWards([]); // Reset wards
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching districts:", error);
          setLoading(false);
        });
    } else {
      setDistricts([]);
      setWards([]);
    }
  }, [selectedProvince]);
  // Fetch Xã/Phường khi quận/huyện thay đổi
  useEffect(() => {
    if (selectedDistrict) {
      setLoading(true);
      axios
        .get(`https://main.doapp.vn/api/place/vn/wards/${selectedDistrict}`)
        .then((response) => {
          setWards(response?.data?.data || []);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching wards:", error);
          setLoading(false);
        });
    } else {
      setWards([]);
    }
  }, [selectedDistrict]);

  if (!formData.info_questions) {
    const initQuestions = [
      { title: "Điểm mạnh của bản thân ?", answer: "", note: "" },
      { title: "Điểm yếu của bản thân ?", answer: "", note: "" },
      { title: "Làm ngoài giờ, đi công tác xa ?", answer: "", note: "" },
      { title: "Đảm nhận công việc ở tỉnh/ TP khác ?", answer: "", note: "" },
      {
        title:
          "Anh/Chị có bạn bè hay người thân đang làm việc tại Công ty Thiên Lý hay Công ty cùng ngành không? (F&B) ?",
        answer: "",
        note: "",
      },
      {
        title:
          "Anh/Chị đã từng nộp đơn dự tuyển vào làm việc tại Công ty hay chưa ?",
        answer: "",
        note: "",
      },
      {
        title: "Anh/Chị biết thông tin tuyển dụng qua kênh nào ?",
        answer: "",
        note: "",
      },
      { title: "Người tham khảo tại công ty gần nhất ?", answer: "", note: "" },
    ];
    setFormData((prevState) => ({
      ...prevState,
      info_questions: initQuestions,
    }));
  }

  const fetchDataCVInfo = useCallback(async () => {
    setLoading(true);

    try {
      setLoading(true);
      const response = await axios.get(
        `https://api.comnieuthienly.vn/api/cv-info/${id}`
      );
      const userAgent = window.navigator.userAgent;
      let checkIOS = false;

      if (/iPhone|iPad|iPod/i.test(userAgent) || /Zalo/i.test(userAgent)) {
        checkIOS = true;
      }
      const questionsInit = {};
      response.data?.data?.info_questions?.forEach((item, index) => {
        const fieldName = item.title;
        questionsInit[fieldName] = item.answer ?? item.note;
      });

      const innitData = {
        ...response.data.data,
        is_fulltime:
          response.data?.data?.is_fulltime == null
            ? false
            : response.data?.data?.is_fulltime,
        time_can_be_work: convertToDateFormat(
          response?.data?.data?.time_can_be_work
        ),
        birthday: convertToDateFormat(response?.data?.data?.birthday),
        date_range: convertToDateFormat(response?.data?.data?.date_range),
        is_disability:
          response?.data?.data?.is_disability === 0 ||
          response?.data?.data?.is_disability == "false" ||
          !response?.data?.data?.is_disability
            ? "false"
            : "true",
        // Fake data
        overtime: true,
        onsite: true,
        introduce: true,
        passed: true,
        recruitment_from: true,
        ...questionsInit,
      };
      console.log("innitData", innitData);

      setFormData(innitData);
      form.setFieldsValue(innitData);

      // Cập nhật state với dữ liệu trả về từ API
      setLoading(false);
    } catch (err) {
      console.error("Error fetching data:", err);
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchDataCVInfo(); // Gọi api khi component mount
  }, [fetchDataCVInfo]);

  // submit
  const handleSubmit = (values) => {
    console.log("valúes", values);

    // formRef.current.requestSubmit();
    const dataSend = {
      position: values.position,
      is_fulltime: values.is_fulltime,
      hope_salary:
        typeof values?.hope_salary === "string"
          ? Number(values?.hope_salary?.replace(/,/g, ""))
          : formData?.hope_salary,
      time_can_be_work: convertToDateSubmit(values.time_can_be_work),
      name: values.name,
      sex: values.sex,
      birthday: convertToDateSubmit(values.birthday),
      hometown: values.hometown,
      cccd: values.cccd,
      date_range: convertToDateSubmit(values.date_range),
      issued_by: values.issued_by,
      temporary_address:
        detailedAddress == ""
          ? values.temporary_address
          : `${detailedAddress}, ${
              wards.find((ward) => ward.id == selectedWard).name
            }, ${
              districts.find((district) => district.id == selectedDistrict).name
            }, ${
              provinces.find((province) => province.id == selectedProvince).name
            }`,
      permanent_address:
        detailedAddress2 == ""
          ? values.permanent_address
          : `${detailedAddress2}, ${
              wards2.find((ward) => ward.id == selectedWard2).name
            }, ${
              districts2.find((district) => district.id == selectedDistrict2)
                .name
            }, ${
              provinces2.find((province) => province.id == selectedProvince2)
                .name
            }`,
      email: values.email,
      phone: values.phone,
      height: values.height,
      weight: values.weight,
      health_condition: values.health_condition,
      is_disability: JSON.parse(values.is_disability.toLowerCase()),
      married_status: values.married_status,
      children_count: values.children_count,
      nationality: values.nationality,
      nation: values.nation,
      religion: values.religion,
      relatives_name: values.relatives_name,
      relatives_relationship: values.relatives_relationship,
      relatives_address: values.relatives_address,
      relatives_phone: values.relatives_phone,
      info_questions: values.info_questions,
    };

    console.log("dataSend", dataSend);

    // if (!validateForm(dataSend)) {
    //   return;
    // }

    setLoading(true);
    axios
      .put(
        `https://api.comnieuthienly.vn/api/confirm-invite-pv/${id}`,
        dataSend
      )
      .then((response) => {
        setLoading(false);
        navigate("/confirm-success");
        console.log("Response:", response.data);
        toast.success(response?.data?.msg);
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
        toast.error(error.message);
      });
  };

  function validateDate(dateString) {
    // Regex for YYYY-MM-DD and optional HH:MM:SS
    const regex = /^\d{4}-\d{2}-\d{2}( \d{2}:\d{2}(:\d{2})?)?$/;

    // Check if the date matches the pattern
    if (!regex.test(dateString)) {
      return false;
    }

    // Parse the date
    const date = new Date(dateString);
    // Check if date is valid
    return !isNaN(date.getTime());
  }

  const validateForm = (dataSend) => {
    if (!dataSend.position) {
      toast.error("Vui lòng nhập vị trí ứng tuyển.");
      return false;
    }

    if (!dataSend.hope_salary) {
      toast.error("Vui lòng nhập mức lương mong muốn.");
      return false;
    }

    if (!dataSend.time_can_be_work) {
      toast.error("Vui lòng nhập thời gian có thể nhận việc.");
      return false;
    } else {
      if (!validateDate(dataSend.time_can_be_work)) {
        toast.error("Ngày có thể nhận việc không hợp lệ.");
        return false;
      }
    }

    if (!dataSend.name) {
      toast.error("Vui lòng nhập họ và tên.");
      return false;
    }

    if (!dataSend.birthday) {
      toast.error("Vui lòng nhập ngày sinh.");
      return false;
    } else {
      if (!validateDate(dataSend.birthday)) {
        toast.error("Ngày sinh không hợp lệ.");
        return false;
      }
    }

    if (
      !dataSend &&
      !dataSend.info_questions.every((question) => question.answer)
    ) {
      toast.error("Vui lòng trả lời tất cả các câu hỏi.");
      return false;
    }

    if (!dataSend.hometown) {
      toast.error("Vui lòng nhập nơi sinh.");
      return false;
    }

    if (!dataSend.cccd) {
      toast.error("Vui lòng nhập số CCCD.");
      return false;
    }

    if (!dataSend.date_range) {
      toast.error("Vui lòng nhập ngày cấp.");
      return false;
    } else {
      if (!validateDate(dataSend.date_range)) {
        toast.error("Ngày cấp không hợp lệ.");
        return false;
      }
    }

    if (!dataSend.issued_by) {
      toast.error("Vui lòng nhập nơi cấp.");
      return false;
    }

    if (!dataSend.permanent_address) {
      toast.error("Vui lòng nhập địa chỉ thường trú.");
      return false;
    }

    if (!dataSend.temporary_address) {
      toast.error("Vui lòng nhập địa chỉ tạm trú.");
      return false;
    }

    if (!dataSend.email) {
      toast.error("Vui lòng nhập email.");
      return false;
    }

    if (!dataSend.phone) {
      toast.error("Vui lòng nhập số điện thoại.");
      return false;
    }

    if (!dataSend.height) {
      toast.error("Vui lòng nhập chiều cao.");
      return false;
    }

    if (!dataSend.weight) {
      toast.error("Vui lòng nhập cân nặng.");
      return false;
    }

    if (!dataSend.health_condition) {
      toast.error("Vui lòng nhập tình trạng sức khỏe.");
      return false;
    }

    if (!dataSend.married_status) {
      toast.error("Vui lòng nhập tình trạng hôn nhân.");
      return false;
    }

    if (
      !dataSend &&
      !dataSend.info_questions.every((question) => question.answer)
    ) {
      toast.error("Vui lòng trả lời tất cả các câu hỏi.");
      return false;
    }

    return true;
  };

  const onFinish = (values) => {
    console.log("Success:");
    console.log("formData", formData);
    console.log("@", values);

    handleSubmit({ ...formData, ...values });
  };
  const [form] = Form.useForm();
  const onFinishFailed = (errorInfo) => {
    console.log("errorInfo", errorInfo);

    const firstErrorField = errorInfo.errorFields[0]?.name[0];
    form.scrollToField(firstErrorField);
  };

  // Fetch Tỉnh/Thành
  useEffect(() => {
    setLoading(true);
    axios
      .get("https://main.doapp.vn/api/place/vn/province")
      .then((response) => {
        const provinces = response?.data?.data || [];
        provinces.sort((a, b) => {
          if (a.id === 1) return -1; // Hà Nội first
          if (b.id === 1) return 1;
          if (a.id === 79) return -1; // Hồ Chí Minh second
          if (b.id === 79) return 1;
          return 0;
        });
        setProvinces2(provinces);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching provinces:", error);
        toast.error("Có lỗi xảy ra khi lấy danh sách tỉnh/thành phố.");
        setLoading(false);
      });
  }, []);

  // Fetch Quận/Huyện khi tỉnh/thành thay đổi
  useEffect(() => {
    if (selectedProvince2) {
      setLoading(true);
      axios
        .get(`https://main.doapp.vn/api/place/vn/district/${selectedProvince2}`)
        .then((response) => {
          setDistricts2(response?.data?.data || []);
          setSelectedDistrict2(""); // Reset selected district
          setWards2([]); // Reset wards
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching districts:", error);
          toast.error("Có lỗi xảy ra khi lấy danh sách quận/huyện.");
          setLoading(false);
        });
    } else {
      setDistricts2([]);
      setWards2([]);
    }
  }, [selectedProvince2]);

  // Fetch Xã/Phường khi quận/huyện thay đổi
  useEffect(() => {
    if (selectedDistrict2) {
      setLoading(true);
      axios
        .get(`https://main.doapp.vn/api/place/vn/wards/${selectedDistrict2}`)
        .then((response) => {
          setWards2(response?.data?.data || []);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching wards:", error);
          toast.error("Có lỗi xảy ra khi lấy danh sách xã/phường.");
          setLoading(false);
        });
    } else {
      setWards2([]);
    }
  }, [selectedDistrict2]);

  const handleChangeDay = (e) => {
    let { name, value } = e.target;

    let inputValue = value.replace(/[^0-9]/g, "");
    if (inputValue.length >= 2) {
      inputValue = inputValue.slice(0, 2) + "/" + inputValue.slice(2);
    }
    if (inputValue.length >= 5) {
      inputValue = inputValue.slice(0, 5) + "/" + inputValue.slice(5);
    }
    form.setFieldValue(name, inputValue);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Backspace" || e.key === "Delete") {
      let { name } = e.target;
      form.setFieldValue(name, "");
    }
  };

  return (
    <div className="">
      <div className="flex flex-col items-center justify-center mb-8">
        <div className="card-banner banner-img p-style"></div>
        <Form
          className="p-full flex flex-col items-center justify-center"
          ref={formRef}
          // onSubmit={(e) => {
          //   console.log("formData", formData);

          //   handleSubmit(e);
          // }}
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <div className="wrap-content p-style">
            <div className="line"></div>
            <div className="text">
              <span className="text-heading">
                PHIẾU BỔ SUNG THÔNG TIN ỨNG VIÊN
              </span>
              <div className="line-top"></div>
              <p className="text-requied">* Chỉ ra những câu hỏi bắt buộc</p>
            </div>
          </div>
          <div className="wrap-content p-style">
            <div
              className="line"
              style={{ padding: "12px 24px", height: "auto" }}
            >
              <span
                className="text-content"
                style={{ color: "rgb(0, 0, 0)", fontSize: "12pt" }}
              >
                I. THÔNG TIN CHUNG
              </span>
            </div>
            <div className="text-content" style={{ marginTop: "50px" }}>
              {/* Position */}
              <div className="form-group flex justify-between items-center">
                <label
                  className="text-style display--block mg"
                  htmlFor="position"
                >
                  Vị trí ứng tuyển
                  <span
                    className="style-required"
                    aria-label="Required question"
                  >
                    *
                  </span>
                </label>

                <Form.Item
                  name="position"
                  className="w-full"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập vị trí ứng tuyển",
                    },
                  ]}
                >
                  <input
                    type="text"
                    name="position"
                    className="form-input"
                    placeholder="Nhập vị trí ứng tuyển"
                  />
                </Form.Item>
              </div>
              {/* Work time */}
              <div className="form-group flex justify-between items-center">
                <label
                  className="text-style display--block mg"
                  style={{ width: "40%" }}
                >
                  Thời gian làm việc
                  <span
                    className="style-required"
                    aria-label="Required question"
                  >
                    {" "}
                    *
                  </span>
                </label>
                <Form.Item
                  className="w-full"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng chọn thời gian làm việc",
                    },
                  ]}
                >
                  <div className="flex flex-row justify-between items-center w--60">
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        name="is_fulltime"
                        checked={formData.is_fulltime == true}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            is_fulltime: true,
                          });
                        }}
                        style={{ marginRight: 10 }}
                      />
                      Toàn thời gian
                    </label>
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        name="is_fulltime"
                        checked={formData.is_fulltime == false}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            is_fulltime: false,
                          });
                        }}
                        style={{ marginRight: 10 }}
                      />
                      Bán thời gian
                    </label>
                  </div>
                </Form.Item>
              </div>
              {/* Salary ofter */}
              <div className="form-group flex justify-between items-center">
                <label
                  className="text-style display--block mg"
                  htmlFor="salary"
                >
                  Mức lương mong muốn
                  <span
                    className="style-required"
                    aria-label="Required question"
                  >
                    *
                  </span>
                </label>
                <Form.Item
                  name="hope_salary"
                  className="w-full"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập mức lương mong muốn",
                    },
                  ]}
                >
                  <div className="input-container">
                    <NumericFormat
                      id="currency-input"
                      className="form-input"
                      thousandSeparator=","
                      decimalSeparator="."
                      allowNegative={false}
                      placeholder="Nhập số tiền"
                      customInput="input"
                      value={formData.hope_salary ?? ""}
                      onValueChange={(values) => {
                        setFormData(() => ({
                          ...formData,
                          hope_salary: values.value,
                        }));
                      }}
                    />
                  </div>
                </Form.Item>
              </div>
              <div className="form-group flex justify-between items-center">
                <label
                  className="text-style display--block mg"
                  htmlFor="time_can_be_work"
                >
                  Thời gian có thể nhận việc
                  <span
                    className="style-required"
                    aria-label="Required question"
                  >
                    *
                  </span>
                </label>
                <Form.Item
                  name="time_can_be_work"
                  className="w-full"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập thời gian có thể nhận việc",
                    },
                    {
                      pattern:
                        /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/,
                      message: "Vui lòng nhập ngày theo định dạng dd/mm/yyyy",
                    },
                  ]}
                >
                  <input
                    type="text"
                    name="time_can_be_work"
                    placeholder="dd/mm/yyyy"
                    className="form-input"
                    onChange={handleChangeDay}
                    onKeyDown={handleKeyDown}
                  />
                </Form.Item>
              </div>
            </div>
          </div>

          <div className="wrap-content p-style">
            <div
              className="line"
              style={{ padding: "12px 24px", height: "auto" }}
            >
              <span
                className="text-content"
                style={{ color: "rgb(0, 0, 0)", fontSize: "12pt" }}
              >
                II. THÔNG TIN CÁ NHÂN
              </span>
            </div>
            <div className="text-content" style={{ marginTop: "50px" }}>
              {/* Họ và tên */}
              <div className="form-group flex justify-between items-center">
                <label className="text-style display--block mg" htmlFor="name">
                  Họ Và Tên
                  <span
                    className="style-required"
                    aria-label="Required question"
                  >
                    *
                  </span>
                </label>
                <Form.Item
                  name="name"
                  className="w-full"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập họ và tên",
                    },
                  ]}
                >
                  <input
                    type="text"
                    className="form-input"
                    placeholder="Nhập họ và tên"
                  />
                </Form.Item>
              </div>
              {/* Ngày sinh */}
              <div className="form-group flex justify-between items-center">
                <label className="text-style display--block mg" htmlFor="dob">
                  Ngày sinh
                  <span
                    className="style-required"
                    aria-label="Required question"
                  >
                    {" "}
                    *
                  </span>
                </label>
                <Form.Item
                  name="birthday"
                  className="w-full"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập ngày sinh",
                    },
                    {
                      pattern:
                        /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/,
                      message: "Vui lòng nhập ngày theo định dạng dd/mm/yyyy",
                    },
                  ]}
                >
                  <input
                    type="text"
                    name="birthday"
                    placeholder="dd/mm/yyyy"
                    className="form-input"
                    onChange={handleChangeDay}
                    onKeyDown={handleKeyDown}
                  />
                </Form.Item>
              </div>
              {/* Giới tính */}
              <div className="form-group flex justify-between items-center">
                <label className="text-style display--block mg" htmlFor="sex">
                  Giới tính
                  <span
                    className="style-required"
                    aria-label="Required question"
                  >
                    {" "}
                    *
                  </span>
                </label>
                <Form.Item
                  name="sex"
                  className="w-full"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng chọn giới tính",
                    },
                  ]}
                >
                  <div className="flex flex-row justify-start w--70">
                    <label className="mr-5 flex items-center">
                      <input
                        type="radio"
                        name="sex"
                        value="1"
                        checked={formData.sex == 1}
                        onChange={handleRadioChange}
                      />
                      Nam
                    </label>
                    <label className="mr-5 flex items-center">
                      <input
                        type="radio"
                        name="sex"
                        value="2"
                        checked={formData.sex == 2}
                        onChange={handleRadioChange}
                      />
                      Nữ
                    </label>
                    <label className="mr-5 flex items-center">
                      <input
                        type="radio"
                        name="sex"
                        value="0"
                        checked={formData.sex == 0}
                        onChange={handleRadioChange}
                      />
                      Khác
                    </label>
                  </div>
                </Form.Item>
              </div>
              {/* nơi sinh */}
              <div className="form-group flex justify-between items-center">
                <label
                  className="text-style display--block mg"
                  htmlFor="hometown"
                >
                  Nơi sinh
                  <span
                    className="style-required"
                    aria-label="Required question"
                  >
                    {" "}
                    *
                  </span>
                </label>
                <Form.Item
                  name="hometown"
                  className="w-full"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập nơi sinh",
                    },
                  ]}
                >
                  <input
                    type="text"
                    className="form-input"
                    placeholder="Nơi sinh"
                  />
                </Form.Item>
              </div>
              <div className="flex  justify-between items-center">
                {/* cccd*/}
                <div className="form-group" style={{ width: "45%" }}>
                  <label className="text-style display--block" htmlFor="cccd">
                    Số CCCD
                    <span
                      className="style-required"
                      aria-label="Required question"
                    >
                      {" "}
                      *
                    </span>
                  </label>
                  <Form.Item
                    name="cccd"
                    className="!w-full"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập CCCD",
                      },
                    ]}
                  >
                    <input
                      type="text"
                      className="form-input"
                      placeholder="Nhập cccd của bạn"
                    />
                  </Form.Item>
                </div>
                {/* Ngày cấp */}
                <div className="form-group" style={{ width: "45%" }}>
                  <label
                    className="text-style display--block"
                    htmlFor="date_range"
                  >
                    NGÀY CẤP
                    <span
                      className="style-required"
                      aria-label="Required question"
                    >
                      {" "}
                      *
                    </span>
                  </label>
                  <Form.Item
                    name="date_range"
                    className="!w-full"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập ngày cấp",
                      },
                      {
                        pattern:
                          /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/,
                        message: "Vui lòng nhập ngày theo định dạng dd/mm/yyyy",
                      },
                    ]}
                  >
                    <input
                      type="text"
                      name="date_range"
                      placeholder="dd/mm/yyyy"
                      className="form-input"
                      onChange={handleChangeDay}
                      onKeyDown={handleKeyDown}
                    />
                  </Form.Item>
                </div>
              </div>
              {/* Nơi cấp */}
              <div className="form-group">
                <label
                  className="text-style display--block"
                  htmlFor="issued_by"
                >
                  NƠI CẤP
                  <span
                    className="style-required"
                    aria-label="Required question"
                  >
                    {" "}
                    *
                  </span>
                </label>
                <Form.Item
                  name="issued_by"
                  className="!w-full"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập nơi cấp",
                    },
                  ]}
                >
                  <input
                    type="text"
                    className="form-input"
                    placeholder="Nhập thông tin nơi cấp"
                  />
                </Form.Item>
              </div>
              {/* Địa chỉ thường trú */}
              <div className="form-group">
                <label
                  className="text-style display--block display--block"
                  htmlFor="permanent_address"
                >
                  ĐỊA CHỈ THƯỜNG TRÚ (KHÔNG VIẾT TẮT & GHI NHƯ CMND/CCCD)
                  <span
                    className="style-required"
                    aria-label="Required question"
                  >
                    {" "}
                    *
                  </span>
                </label>
                {!show2 && (
                  <textarea
                    type="text"
                    name="permanent_address"
                    onClick={() => {
                      setShow2(!show2);
                    }}
                    value={formData?.permanent_address}
                    onChange={handleChange}
                    className="form-input"
                    placeholder="Nhập địa chỉ thường trú"
                  />
                )}
                {show2 && (
                  <>
                    <div className="form-group">
                      <select
                        id="province2"
                        class="form-input"
                        name="province2"
                        value={selectedProvince2}
                        onChange={(e) => setSelectedProvince2(e.target.value)}
                        required
                      >
                        <option value="">Chọn Tỉnh/Thành</option>
                        {provinces2.map((province) => (
                          <option key={province.id} value={province.id}>
                            {province.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    {/* Quận/Huyện */}
                    <div className="form-group">
                      <select
                        id="district2"
                        class="form-input"
                        name="district2"
                        value={selectedDistrict2}
                        onChange={(e) => setSelectedDistrict2(e.target.value)}
                        disabled={!selectedProvince2}
                        required
                      >
                        <option value="">Chọn Quận/Huyện</option>
                        {districts2.map((district) => (
                          <option key={district.id} value={district.id}>
                            {district.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    {/* Xã/Phường */}
                    <div className="form-group">
                      <select
                        id="ward2"
                        class="form-input"
                        name="ward2"
                        value={selectedWard2}
                        onChange={(e) => setSelectedWard2(e.target.value)}
                        disabled={!selectedDistrict2}
                        required
                      >
                        <option value="">Chọn Xã/Phường</option>
                        {wards2.map((ward) => (
                          <option key={ward.id} value={ward.id}>
                            {ward.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    {/* Địa chỉ chi tiết */}
                    <div className="form-group">
                      <label
                        style={{ paddingLeft: "15px" }}
                        htmlFor="detailedAddress2"
                      >
                        Địa chỉ chi tiết
                        <span
                          className="style-required"
                          aria-label="Required question"
                        >
                          {" "}
                          *
                        </span>
                      </label>
                      <div style={{ height: 5 }}></div>
                      <Form.Item
                        name="detailedAddress2"
                        className="!w-full"
                        rules={[
                          {
                            required: true,
                            message: "Vui lòng địa chỉ thường trú",
                          },
                        ]}
                      >
                        <textarea
                          type="text"
                          name="detailedAddress2"
                          value={detailedAddress2} // Gắn giá trị của detailedAddress vào input
                          onChange={(e) => {
                            setDetailedAddress2(e.target.value);
                          }} // Sử dụng hàm handleAddressChange để lưu giá trị
                          className="form-input"
                          placeholder="Nhập địa chỉ chi tiết"
                        />
                      </Form.Item>
                    </div>
                  </>
                )}
              </div>

              {/* Địa chỉ tạm trú */}
              <div className="form-group">
                <div className="flex flex-row content-start items-baseline">
                  <label class="text-style display--block mr-5" for="province">
                    ĐỊA CHỈ TẠM TRÚ
                    <span class="style-required" aria-label="Required question">
                      {" "}
                      *
                    </span>
                  </label>
                </div>
                {!show && (
                  <textarea
                    onClick={() => {
                      setShow(!show);
                    }}
                    type="text"
                    name="detailedAddress"
                    value={formData.temporary_address} // Gắn giá trị của detailedAddress vào input
                    onChange={handleAddressChange} // Sử dụng hàm handleAddressChange để lưu giá trị
                    className="form-input"
                    placeholder="Nhập địa chỉ tạm trú"
                  />
                )}
                {show && (
                  <>
                    <div className="form-group">
                      <select
                        id="province"
                        class="form-input"
                        name="province"
                        value={selectedProvince}
                        onChange={(e) => setSelectedProvince(e.target.value)}
                        required
                      >
                        <option value="">Chọn Tỉnh/Thành</option>
                        {provinces.map((province) => (
                          <option key={province.id} value={province.id}>
                            {province.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group">
                      <select
                        id="district"
                        class="form-input"
                        name="district"
                        value={selectedDistrict}
                        onChange={(e) => setSelectedDistrict(e.target.value)}
                        disabled={!selectedProvince}
                        required
                      >
                        <option value="">Chọn Quận/Huyện</option>
                        {districts.map((district) => (
                          <option key={district.id} value={district.id}>
                            {district.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group">
                      <select
                        id="ward"
                        class="form-input"
                        name="ward"
                        value={selectedWard}
                        onChange={(e) => setSelectedWard(e.target.value)}
                        disabled={!selectedDistrict}
                        required
                      >
                        <option value="">Chọn Xã/Phường</option>
                        {wards.map((ward) => (
                          <option key={ward.id} value={ward.id}>
                            {ward.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group">
                      <label
                        style={{ paddingLeft: "15px" }}
                        htmlFor="detailedAddress"
                      >
                        Địa chỉ chi tiết
                        <span
                          className="style-required"
                          aria-label="Required question"
                        >
                          {" "}
                          *
                        </span>
                      </label>
                      <div style={{ height: 5 }}></div>
                      <Form.Item
                        name="detailedAddress"
                        className="!w-full"
                        rules={[
                          {
                            required: true,
                            message: "Vui lòng địa chỉ thường trú",
                          },
                        ]}
                      >
                        <textarea
                          type="text"
                          name="detailedAddress"
                          value={detailedAddress} // Gắn giá trị của detailedAddress vào input
                          onChange={handleAddressChange} // Sử dụng hàm handleAddressChange để lưu giá trị
                          className="form-input"
                          placeholder="Nhập địa chỉ chi tiết"
                        />
                      </Form.Item>
                    </div>
                  </>
                )}
              </div>
              <div className="flex flex-wrap justify-between items-center">
                {/* Số điện thoại */}
                <div className="form-group w--45">
                  <label
                    className="text-style display--block display--block"
                    htmlFor="phone"
                  >
                    Điện thoại di động
                    <span
                      className="style-required"
                      aria-label="Required question"
                    >
                      {" "}
                      *
                    </span>
                  </label>
                  <Form.Item
                    name="phone"
                    className="!w-full"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập số điện thoại",
                      },
                      {
                        pattern: /^[0-9]{9,10}$/,
                        message: "Vui lòng nhập số điện thoại hợp lệ",
                      },
                    ]}
                  >
                    <input
                      type="tel"
                      name="phone"
                      value={formData?.phone}
                      onChange={handleChange}
                      className="form-input"
                      pattern="[0-9]{10}"
                      placeholder="Nhập số điện thoại"
                    />
                  </Form.Item>
                </div>
                {/* Email */}
                <div className="form-group w--45">
                  <label
                    className="text-style display--block display--block"
                    htmlFor="email"
                  >
                    Email
                    <span
                      className="style-required"
                      aria-label="Required question"
                    >
                      {" "}
                      *
                    </span>
                  </label>
                  <Form.Item
                    name="email"
                    className="!w-full"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập email",
                      },
                    ]}
                  >
                    <input
                      type="email"
                      name="email"
                      value={formData?.email}
                      onChange={handleChange}
                      className="form-input"
                      placeholder="Nhập địa chỉ email"
                    />
                  </Form.Item>
                </div>
              </div>
              {/* Tình trạng hôn nhân */}
              <div className="form-group flex justify-between items-center">
                <label
                  className="text-style display--block display--block"
                  htmlFor="marry"
                  style={{ marginTop: "15px" }}
                >
                  Tình trạng hôn nhân
                  <span
                    className="style-required"
                    aria-label="Required question"
                  >
                    {" "}
                    *
                  </span>
                </label>

                <div className="flex  justify-between items-center">
                  <Form.Item
                    name="married_status"
                    className="!w-full flex-1 ml-4"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng chọn tình trạng hôn nhân",
                      },
                    ]}
                  >
                    <div className="flex items-center">
                      <label className="mr-5 flex items-center">
                        <input
                          type="radio"
                          name="married_status"
                          value="0"
                          checked={formData.married_status == 0}
                          onChange={handleRadioChange}
                        />
                        Độc thân
                      </label>
                      <label className="mr-5 flex items-center">
                        <input
                          type="radio"
                          name="married_status"
                          value="1"
                          checked={formData.married_status == 1}
                          onChange={handleRadioChange}
                        />
                        Có gia đình
                      </label>
                      <label className="mr-5 flex items-center">
                        <input
                          type="radio"
                          name="married_status"
                          value="2"
                          checked={formData.married_status == 2}
                          onChange={handleRadioChange}
                        />
                        Ly hôn
                      </label>
                    </div>
                  </Form.Item>

                  <label className="flex items-center">
                    Số con:
                    <Form.Item name="children_count" className="!w-full flex-1">
                      <input
                        type="number"
                        className="form-number"
                        style={{ width: "50px", marginLeft: "5px" }}
                        // onChange={handleRadioChange}
                      />
                    </Form.Item>
                  </label>
                </div>
              </div>
              <div className="flex flex-wrap justify-between items-center">
                {/* height */}
                <div className="form-group w--30">
                  <label
                    className="text-style display--block display--block"
                    htmlFor="height"
                  >
                    Chiều cao
                    <span
                      className="style-required"
                      aria-label="Required question"
                    >
                      {" "}
                      *
                    </span>
                  </label>
                  <Form.Item
                    name="height"
                    className="!w-full"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập chiều cao",
                      },
                    ]}
                  >
                    <input
                      type="number"
                      name="height"
                      value={formData.height}
                      onChange={handleChange}
                      className="form-input"
                      pattern="[0-9]{10}"
                      placeholder="Nhập thông tin"
                    />
                  </Form.Item>
                </div>
                {/* Can nang */}
                <div className="form-group w--30">
                  <label className="text-style display--block" htmlFor="weight">
                    Cân nặng
                    <span
                      className="style-required"
                      aria-label="Required question"
                    >
                      {" "}
                      *
                    </span>
                  </label>
                  <Form.Item
                    name="weight"
                    className="!w-full"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập cân nặng",
                      },
                    ]}
                  >
                    <input
                      type="weight"
                      name="weight"
                      value={formData.weight}
                      onChange={handleChange}
                      className="form-input"
                      placeholder="Nhập cân nặng của bạn"
                    />
                  </Form.Item>
                </div>
                {/* Khuyết tật */}
                <div className="form-group w--30">
                  <label
                    className="text-style display--block "
                    htmlFor="is_disability"
                  >
                    Khuyết tật
                    <span
                      className="style-required"
                      aria-label="Required question"
                    >
                      {" "}
                      *
                    </span>
                  </label>
                  <Form.Item
                    name="is_disability"
                    className="!w-full"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng chọn",
                      },
                    ]}
                  >
                    <div className="flex justify-start items-center">
                      <label className="mr-5">
                        <input
                          type="radio"
                          name="is_disability"
                          value={true}
                          checked={formData.is_disability == "true"}
                          onChange={handleRadioChange}
                        />
                        Có
                      </label>
                      <label className="mr-5">
                        <input
                          type="radio"
                          name="is_disability"
                          value={false}
                          checked={formData.is_disability == "false"}
                          onChange={handleRadioChange}
                        />
                        Không
                      </label>
                    </div>
                  </Form.Item>
                </div>
              </div>
              {/* Tình trạng và sức khoẻ */}
              <div className="form-group">
                <label
                  className="text-style display--block"
                  htmlFor="health_condition"
                >
                  Tình trạng và sức khoẻ
                  <span
                    className="style-required"
                    aria-label="Required question"
                  >
                    *
                  </span>
                </label>
                <Form.Item
                  name="health_condition"
                  className="!w-full"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập tình trạng sức khoẻ của bạn",
                    },
                  ]}
                >
                  <textarea
                    type="text"
                    name="health_condition"
                    value={formData.health_condition}
                    onChange={handleChange}
                    className="form-input"
                    placeholder="Nhập thông tin tình trạng sức khoẻ của bạn"
                  />
                </Form.Item>
              </div>
              <div className="flex flex-wrap justify-between items-center">
                {/* Quốc tịch */}
                <div className="form-group w--30">
                  <label
                    className="text-style display--block"
                    htmlFor="nationality"
                  >
                    Quốc tịch
                    <span
                      className="style-required"
                      aria-label="Required question"
                    >
                      {" "}
                      *
                    </span>
                  </label>
                  <Form.Item
                    name="nationality"
                    className="!w-full"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập quốc tịch",
                      },
                    ]}
                  >
                    <input
                      type="text"
                      name="nationality"
                      value={formData.nationality}
                      onChange={handleChange}
                      className="form-input"
                      placeholder="Nhập thông tin"
                    />
                  </Form.Item>
                </div>
                {/* Dân tộc  */}
                <div className="form-group w--30">
                  <label className="text-style display--block" htmlFor="nation">
                    Dân tộc
                    <span
                      className="style-required"
                      aria-label="Required question"
                    >
                      {" "}
                      *
                    </span>
                  </label>
                  <Form.Item
                    name="nation"
                    className="!w-full"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập dân tộc",
                      },
                    ]}
                  >
                    <input
                      type="nation"
                      name="nation"
                      value={formData.nation}
                      onChange={handleChange}
                      className="form-input"
                      placeholder="Nhập thông tin"
                    />
                  </Form.Item>
                </div>
                {/* Tôn giáo  */}
                <div className="form-group w--30">
                  <label
                    className="text-style display--block"
                    htmlFor="religion"
                  >
                    Tôn giáo
                  </label>
                  <Form.Item
                    name="religion"
                    className="!w-full"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập tôn giáo",
                      },
                    ]}
                  >
                    <input
                      type="religion"
                      name="religion"
                      value={formData.religion}
                      onChange={handleChange}
                      className="form-input"
                      placeholder="Nhập thông tin"
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
          <div className="wrap-content p-style">
            <div
              className="line"
              style={{ padding: "12px 24px", height: "auto" }}
            >
              <span
                className="text-content"
                style={{ color: "rgb(0, 0, 0)", fontSize: "12pt" }}
              >
                III. NGƯỜI BÁO TIN TRONG TRƯỜNG HỢP CẦN THIẾT
              </span>
            </div>
            <div className="text-content mt--50">
              <div className="flex flex-wrap justify-between items-center">
                <div className="form-group w--45">
                  <label className="text-style display--block" htmlFor="height">
                    Họ và tên
                    <span
                      className="style-required"
                      aria-label="Required question"
                    >
                      {" "}
                      *
                    </span>
                  </label>
                  <Form.Item
                    name="relatives_name"
                    className="!w-full"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập tên người báo tin",
                      },
                    ]}
                  >
                    <input
                      type="text"
                      name="relatives_name"
                      value={formData.relatives_name}
                      onChange={handleChange}
                      className="form-input"
                      placeholder="Nhập thông tin"
                    />
                  </Form.Item>
                </div>
                <div className="form-group w--45">
                  <label className="text-style display--block" htmlFor="height">
                    Mối quan hệ
                    <span
                      className="style-required"
                      aria-label="Required question"
                    >
                      {" "}
                      *
                    </span>
                  </label>
                  <Form.Item
                    name="relatives_relationship"
                    className="!w-full"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập mối quan hệ với người báo tin",
                      },
                    ]}
                  >
                    <input
                      type="text"
                      name="relatives_relationship"
                      value={formData.relatives_relationship}
                      onChange={handleChange}
                      className="form-input"
                      placeholder="Nhập thông tin"
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="flex flex-wrap justify-between items-center">
                <div className="form-group w--45">
                  <label className="text-style display--block" htmlFor="height">
                    Điên thoại
                    <span
                      className="style-required"
                      aria-label="Required question"
                    >
                      {" "}
                      *
                    </span>
                  </label>
                  <Form.Item
                    name="relatives_phone"
                    className="!w-full"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập số điện thoại người báo tin",
                      },
                      {
                        pattern: /^[0-9]{9,10}$/,
                        message: "Vui lòng nhập số điện thoại hợp lệ",
                      },
                    ]}
                  >
                    <input
                      type="tel"
                      name="relatives_phone"
                      value={formData.relatives_phone}
                      onChange={handleChange}
                      className="form-input"
                      placeholder="Nhập thông tin"
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
          <div className="wrap-content p-style">
            <div
              className="line"
              style={{ padding: "12px 24px", height: "auto" }}
            >
              <span
                className="text-content"
                style={{ color: "rgb(0, 0, 0)", fontSize: "12pt" }}
              >
                IV. THÔNG TIN BỔ SUNG: (Vui lòng đánh dấu ☑ vào câu trả lời bạn
                chọn)
              </span>
            </div>
            <div className="text-content mt--50">
              {formData?.info_questions?.map((item, index) => (
                <div key={index} className="form-group">
                  <label
                    className="text-style display--block"
                    htmlFor="info_questions"
                  >
                    {item.title}
                    <span
                      className="style-required"
                      aria-label="Required question"
                    >
                      {" "}
                      *
                    </span>
                  </label>
                  {item.title === "Điểm mạnh của bản thân ?" ||
                  item.title === "Điểm yếu của bản thân ?" ? (
                    <Form.Item
                      name={item.title}
                      className="!w-full"
                      rules={[
                        {
                          required: true,
                          message:
                            item.title === "Điểm mạnh của bản thân ?"
                              ? "Vui lòng nhập điểm mạnh của bản thân"
                              : "Vui lòng nhập điểm yếu của bản thân",
                        },
                      ]}
                    >
                      <textarea
                        type="text"
                        value={item.answer}
                        onChange={(e) =>
                          handleInputQuestionsChange(index, e.target.value)
                        }
                        className="form-input"
                        placeholder="Nhập thông tin"
                      />
                    </Form.Item>
                  ) : item.title === "Làm ngoài giờ, đi công tác xa ?" ? (
                    <Form.Item
                      name={item.title}
                      className="!w-full"
                      rules={[
                        {
                          required: true,
                          message: "Vui lòng chọn",
                        },
                      ]}
                    >
                      <div className="flex justify-start items-center">
                        <Radio.Group
                          onChange={(e) =>
                            handleRadioQuestionsChange(index, e.target.value)
                          }
                          value={item.answer}
                        >
                          <Radio value={0}>Sẵn sàng</Radio>
                          <Radio value={1}>Đôi khi</Radio>
                          <Radio value={2}>Hạn chế</Radio>
                        </Radio.Group>
                        {/* <label
                          className="mr-5 flex items-center"
                          style={{ margin: "0 25px 0 0" }}
                        >
                          <input
                            type="radio"
                            value="0"
                            checked={item.answer === 0}
                            onChange={(e) =>
                              handleRadioQuestionsChange(index, e.target.value)
                            }
                          />
                          Sẵn sàng
                        </label>
                        <label
                          className="mr-5 flex items-center"
                          style={{ margin: "0 25px 0 0" }}
                        >
                          <input
                            type="radio"
                            value="1"
                            checked={item.answer === 1}
                            onChange={(e) =>
                              handleRadioQuestionsChange(index, e.target.value)
                            }
                          />
                          Đôi khi
                        </label>
                        <label
                          className="mr-5 flex items-center"
                          style={{ margin: "0 25px 0 0" }}
                        >
                          <input
                            type="radio"
                            value="2"
                            required
                            checked={item.answer === 2}
                            onChange={(e) =>
                              handleRadioQuestionsChange(index, e.target.value)
                            }
                          />
                          Hạn chế
                        </label> */}
                      </div>
                    </Form.Item>
                  ) : item.title === "Đảm nhận công việc ở tỉnh/ TP khác ?" ? (
                    <Form.Item
                      name={item.title}
                      className="!w-full"
                      rules={[
                        {
                          required: true,
                          message: "Vui lòng chọn",
                        },
                      ]}
                    >
                      <div className="flex justify-start items-center">
                        <Radio.Group
                          onChange={(e) =>
                            handleRadioQuestionsChange(index, e.target.value)
                          }
                          value={item.answer}
                        >
                          <Radio value={0}>Có thể</Radio>
                          <Radio value={1}>Không</Radio>
                          <Radio value={2}>Suy nghĩ</Radio>
                        </Radio.Group>
                        {/* <label
                          className="mr-5 flex items-center"
                          style={{ margin: "0 25px 0 0" }}
                        >
                          <input
                            type="radio"
                            value="0"
                            checked={item.answer === 0}
                            onChange={(e) =>
                              handleRadioQuestionsChange(index, e.target.value)
                            }
                          />
                          Có thể
                        </label>
                        <label
                          className="mr-5 flex items-center"
                          style={{ margin: "0 25px 0 0" }}
                        >
                          <input
                            type="radio"
                            value="1"
                            checked={item.answer === 1}
                            onChange={(e) =>
                              handleRadioQuestionsChange(index, e.target.value)
                            }
                          />
                          Không
                        </label>
                        <label
                          className="mr-5 flex items-center"
                          style={{ margin: "0 25px 0 0" }}
                        >
                          <input
                            type="radio"
                            value="2"
                            checked={item.answer === 2}
                            onChange={(e) =>
                              handleRadioQuestionsChange(index, e.target.value)
                            }
                          />
                          Suy nghĩ
                        </label> */}
                      </div>
                    </Form.Item>
                  ) : item.title ===
                    "Anh/Chị có bạn bè hay người thân đang làm việc tại Công ty Thiên Lý hay Công ty cùng ngành không? (F&B) ?" ? (
                    <>
                      <Form.Item
                        name={item.title}
                        className="!w-full"
                        rules={[
                          {
                            required: true,
                            message: "Vui lòng chọn",
                          },
                        ]}
                      >
                        <Radio.Group
                          onChange={(e) => {
                            console.log("e", e.target.value);

                            e.stopPropagation();
                            e.preventDefault();
                            handleRadioQuestionsChange(index, e.target.value);
                          }}
                          value={item.answer}
                        >
                          <Radio value={0}>Không</Radio>
                          <Radio value={1}>
                            Có, vui lòng ghi rõ thông tin (họ tên, vị trí, công
                            ty)
                          </Radio>
                        </Radio.Group>
                      </Form.Item>

                      {item.answer === 1 && (
                        <Form.Item
                          name={"custom"}
                          className="!w-full"
                          rules={[
                            {
                              required: true,
                              message: "Vui lòng nhập",
                            },
                          ]}
                          initialValue={item.note}
                        >
                          <textarea
                            type="text"
                            value={item.note}
                            onChange={(e) =>
                              handleChangeNote(index, e.target.value)
                            }
                            className="form-input"
                            style={{ marginTop: "10px" }}
                            placeholder="Nhập thông tin"
                          />
                        </Form.Item>
                      )}
                    </>
                  ) : item.title ===
                    "Anh/Chị đã từng nộp đơn dự tuyển vào làm việc tại Công ty hay chưa ?" ? (
                    <Form.Item
                      name={item.title}
                      className="!w-full"
                      rules={[
                        {
                          required: true,
                          message: "Vui lòng chọn",
                        },
                      ]}
                    >
                      <>
                        <div className="flex flex-wrap justify-between items-center">
                          {/* <label
                            className="mr-5 flex items-center w--30"
                            style={{ margin: 0 }}
                          >
                            <input
                              type="radio"
                              value="0"
                              checked={item.answer === 0}
                              onChange={(e) =>
                                handleRadioQuestionsChange(
                                  index,
                                  e.target.value
                                )
                              }
                            />
                            Chưa
                          </label>
                          <div className="flex justify-between items-center w--60">
                            <label
                              className="mr-5 flex items-center"
                              style={{ margin: 0 }}
                            >
                              <input
                                type="radio"
                                value="1"
                                checked={item.answer === 1}
                                onChange={(e) =>
                                  handleRadioQuestionsChange(
                                    index,
                                    e.target.value
                                  )
                                }
                              />
                              Rồi, vui lòng ghi rõ thời gian
                            </label>
                          </div> */}
                          <Radio.Group
                            onChange={(e) =>
                              handleRadioQuestionsChange(index, e.target.value)
                            }
                            value={item.answer}
                          >
                            <Radio value={0}>Chưa</Radio>
                            <Radio value={1}>
                              Rồi, vui lòng ghi rõ thời gian
                            </Radio>
                          </Radio.Group>
                          {item.answer === 1 && (
                            <input
                              type="date"
                              value={formatDateVN(item?.note)}
                              onChange={(e) =>
                                handleChangeNote(index, e.target.value)
                              }
                              className="form-input"
                              style={{ width: "150px" }}
                            />
                          )}
                        </div>
                      </>
                    </Form.Item>
                  ) : item.title ===
                    "Anh/Chị biết thông tin tuyển dụng qua kênh nào ?" ? (
                    <Form.Item
                      name={item.title}
                      className="!w-full"
                      rules={[
                        {
                          required: true,
                          message: "Vui lòng chọn",
                        },
                      ]}
                    >
                      <>
                        <div className="flex flex-wrap justify-between items-center">
                          {/* <label className="mr-5 flex items-center">
                            <input
                              type="radio"
                              value="0"
                              checked={item.answer === 0}
                              onChange={(e) =>
                                handleRadioQuestionsChange(
                                  index,
                                  e.target.value
                                )
                              }
                            />
                            Webiste tuyển dụng
                          </label>
                          <label className="mr-5 flex items-center">
                            <input
                              type="radio"
                              value="1"
                              checked={item.answer === 1}
                              onChange={(e) =>
                                handleRadioQuestionsChange(
                                  index,
                                  e.target.value
                                )
                              }
                            />
                            Bạn bè/Người thân
                          </label>
                          <label className="mr-5 flex items-center">
                            <input
                              type="radio"
                              value="2"
                              checked={item.answer === 2}
                              onChange={(e) =>
                                handleRadioQuestionsChange(
                                  index,
                                  e.target.value
                                )
                              }
                            />
                            Nhân viên Công ty giới thiệu
                          </label>
                          <label className="mr-5 flex items-center">
                            <input
                              type="radio"
                              value="3"
                              checked={item.answer === 3}
                              onChange={(e) =>
                                handleRadioQuestionsChange(
                                  index,
                                  e.target.value
                                )
                              }
                            />
                            Khác
                          </label> */}
                          <Radio.Group
                            onChange={(e) =>
                              handleRadioQuestionsChange(index, e.target.value)
                            }
                            value={item.answer}
                          >
                            <Radio value={0}>Webiste tuyển dụng</Radio>
                            <Radio value={1}>Bạn bè/Người thân</Radio>
                            <Radio value={2}>
                              Nhân viên Công ty giới thiệu
                            </Radio>
                            <Radio value={3}> Khác</Radio>
                          </Radio.Group>
                        </div>
                      </>
                    </Form.Item>
                  ) : (
                    <Form.Item
                      name={item.title}
                      className="!w-full"
                      rules={[
                        {
                          required: true,
                          message: "Vui lòng nhập",
                        },
                      ]}
                    >
                      <>
                        <textarea
                          type="text"
                          //name="permanent_address"
                          value={item.note}
                          onChange={(e) =>
                            handleChangeNote(index, e.target.value)
                          }
                          className="form-input"
                          placeholder="Nhập rõ Họ Tên, Số Điện Thoại, Chức vụ"
                        />
                      </>
                    </Form.Item>
                  )}
                </div>
              ))}
            </div>
          </div>

          <div style={{ marginTop: 20 }}>
            {/* Submit Button */}
            <div
              className="flex flex-row content-start items-star justify-start submit--button"
              style={{ width: "740px" }}
            >
              <button style={{ marginRight: "25px" }}>
                <Link to={"/danh-muc-ho-so-nhan-vien"}>Trở lại</Link>
              </button>
              <button
                type="submit"
                style={{ backgroundColor: "#e35522", color: "#fff" }}
                disabled={loading}
              >
                Gửi
              </button>
              {loading && <div class="loader"></div>}
            </div>
          </div>
        </Form>

        <a
          className="style-link flex flex-row items-center justify-center"
          href="https://comnieuthienly.vn/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            style={{
              aspectRatio: "auto 74 / 24",
              width: "200px",
            }}
            src="https://i.imgur.com/nB8LOLM.png"
            alt="img-logo-thien-ly"
          />
        </a>
      </div>
      {/* Loading spinner */}
      <div id="loadingSpinner" className="hidden">
        Đang xử lý...
      </div>

      {/* Error notification */}
      <div id="errorPopup" className="hidden">
        <p>Đã xảy ra lỗi khi gửi dữ liệu. Vui lòng thử lại.</p>
      </div>
    </div>
  );
};

export default SupplementCandidateInfo;
